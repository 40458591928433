import { useEffect, useState } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { AnimatePresence, m } from 'framer-motion';
import clsx from 'clsx';

import { FeedbackProvider } from 'services/feedback/FeedbackProvider';
import { useToggle } from 'utils/hooks';

import { Navigation } from './Navigation';
import { FeedbackModal } from './FeedbackModal';

const AnimatedOutlet = () => {
  const o = useOutlet();
  const [outlet] = useState(o);

  return <>{outlet}</>;
};

const initialIsDesktopMenuExpanded = localStorage.getItem('isDesktopMenuExpanded')
  ? localStorage.getItem('isDesktopMenuExpanded') === 'true'
  : window.matchMedia('(min-width: 1024px)').matches;

export const PortalRoot = () => {
  const location = useLocation();
  const [isDesktopMenuExpanded, toggleIsDesktopMenuExpanded] = useToggle(initialIsDesktopMenuExpanded);

  useEffect(() => {
    localStorage.setItem('isDesktopMenuExpanded', isDesktopMenuExpanded.toString());
  }, [isDesktopMenuExpanded]);

  return (
    <FeedbackProvider>
      <div
        className={clsx('h-full transition-all duration-300 md:grid print:block print:w-full', {
          'md:grid-cols-[1fr_calc(100vw-302px)]': isDesktopMenuExpanded,
          'md:grid-cols-[1fr_calc(100vw-89px)]': !isDesktopMenuExpanded,
        })}
      >
        <Navigation
          isDesktopMenuExpanded={isDesktopMenuExpanded}
          toggleIsDesktopMenuExpanded={toggleIsDesktopMenuExpanded}
        />

        <main className="relative w-full max-w-full p-6 ps-8 md:pb-0">
          <AnimatePresence
            mode="wait"
            onExitComplete={() => {
              window.scrollTo(0, 0);
            }}
          >
            <m.div
              key={location.pathname}
              style={{
                height: '100%',
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <AnimatedOutlet />
            </m.div>
          </AnimatePresence>
        </main>
        <FeedbackModal />
      </div>
    </FeedbackProvider>
  );
};
