import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { Collapse, Icon } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { ExpandButton } from 'components/ExpandButton';
import { useToggle } from 'utils/hooks';

export const WhatTheGraphSays = ({ graphIntroduction }: { graphIntroduction: string }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const t = useTranslate();

  return (
    <div className="prose mb-4 rounded-2xl border border-gray-400 shadow-[0px_2px_10px]  new-print-page shadow-gray-800">
      <div
        className="grid grid-cols-[50px_1fr_50px] items-center px-4 py-3 pe-2"
        role="button"
        onClick={() => toggleIsOpen()}
      >
        <Icon name="bulb" className="text-brand-blue min-w-[2rem]" />
        <div className="text-primary font-medium .new-print-page">
          {t('orientation.personality_survey.results.your_personality.graph_explanation')}
        </div>
        <ExpandButton className="text-primary w-[50px] !px-2" isOpen={isOpen} onToggle={() => {}} />
      </div>

      <div className="prose prose-invert p-4">
        <Markdown className=" text-primary whitespace-pre-wrap [&_p]:m-0" remarkPlugins={[remarkGfm]}>
          {graphIntroduction.trim().split('\n')[0]}
        </Markdown>

        <Collapse isOpen={isOpen}>
          <Markdown className=" text-primary mt-6 whitespace-pre-wrap [&_p]:m-0" remarkPlugins={[remarkGfm]}>
            {graphIntroduction.trim().split('\n').slice(1).join('\n')}
          </Markdown>
        </Collapse>
      </div>
    </div>
  );
};
