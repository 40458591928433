import { useState, useEffect, type ReactNode } from 'react';
import _groupby from 'lodash/groupBy';
import { RadarChart, ResponsiveContainer, Pie, Radar, PolarRadiusAxis, PieChart, Cell } from 'recharts';

import { type PersonalitySurveyResults } from 'services/api';

const COLORS = ['#fb91e4', '#4890e0', '#94ff62', '#faff00', '#FFA559'];
const START_ANGLE = 100;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: desired
  useEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return windowSize;
}

const CustomizedDot = ({ cx, cy, payload }: { cx: number; cy: number; payload: { value: number } }) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const radius = isMobile ? 7 : 10;
  const fontSize = isMobile ? 10 : 13;
  const y = cy + 4.5;

  return (
    <g>
      <circle cx={cx} cy={cy} r={radius} fill="white" stroke="#85d02e" />
      <text x={cx} y={y} textAnchor="middle" fill="#111" fontSize={fontSize}>
        {payload.value}
      </text>
    </g>
  );
};

type PersonalityRadarChartProps = {
  data: {
    category: string;
    value: number;
    pie: number;
  }[];
  baseHeight: number;
  chartHeight: number;
};

const PersonalityRadarChart = ({ data, baseHeight, chartHeight }: PersonalityRadarChartProps) => {
  return (
    <div className="absolute start-0 top-0 w-full">
      <ResponsiveContainer width="99%" height={chartHeight}>
        <RadarChart outerRadius={baseHeight} data={data}>
          <Radar
            dataKey="value"
            stroke="#fff"
            strokeWidth={1}
            fill="transparent"
            fillOpacity={0.4}
            className="print:stroke-black"
            // @ts-ignore
            dot={<CustomizedDot />}
          />

          <PolarRadiusAxis domain={[0, 10]} tick={false} angle={50} />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

type PersonalityPieChartProps = {
  data: {
    category: string;
    value: number;
    pie: number;
  }[];
  categories: string[];
  baseHeight: number;
  chartHeight: number;
};

type MaxWidthTextProps = {
  text: string;
  x: number;
  y: number;
  maxWidth: number;
  lineHeight?: number;
  fontSize?: number;
};

const MaxWidthText = ({ text, x, y, maxWidth, lineHeight = 11, fontSize = 10 }: MaxWidthTextProps) => {
  const words = text.split(' ');
  const tspans: ReactNode[] = [];
  let currentLine = '';
  let currentLineLength = 0;

  for (const word of words) {
    const wordLength = word.length * (fontSize * 0.6); // Rough estimation of the word's length in pixels

    if (currentLineLength + wordLength <= maxWidth) {
      currentLine += `${word} `;
      currentLineLength += wordLength;
    } else {
      tspans.push(
        <tspan x={x} dy={lineHeight} key={tspans.length}>
          {currentLine.trim()}
        </tspan>,
      );
      currentLine = `${word} `;
      currentLineLength = wordLength;
    }
  }

  tspans.push(
    <tspan x={x} dy={lineHeight} key={tspans.length}>
      {currentLine.trim()}
    </tspan>,
  );

  return (
    <text x={x - 10} y={y - 20} fill="white" className="print:fill-black" fontSize={fontSize} textAnchor="middle">
      {tspans}
    </text>
  );
};

const CustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  category,
  payload,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  category: string;
  payload: { value: number };
}) => {
  const { width } = useWindowSize();

  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 1.5;

  const screenConstants = {
    desktop: {
      multipliers: {
        base: 1.01,
        near: 1.07,
        far: 1.09,
        furthest: 1.17,
      },
      fontSize: 12,
      maxWidth: 110,
      lineHeight: 11,
    },
    tablet: {
      multipliers: {
        base: 1.25,
        near: 1.25,
        far: 1.2,
        furthest: 1.35,
      },
      fontSize: 8,
      maxWidth: 90,
      lineHeight: 9,
    },
    largeMobile: {
      multipliers: {
        base: 1.15,
        near: 1.2,
        far: 1.25,
        furthest: 1.45,
      },
      fontSize: 7,
      maxWidth: 55,
      lineHeight: 8,
    },
  };

  let constants = screenConstants.desktop;

  if (width < 768) {
    constants = screenConstants.tablet;
  }

  if (width < 425) {
    constants = screenConstants.largeMobile;
  }

  let x2 = cx + radius * Math.cos(-midAngle * RADIAN) * constants.multipliers.base;
  let y2 = cy + radius * Math.sin(-midAngle * RADIAN) * constants.multipliers.base;

  const fontSize = constants.fontSize;
  const maxWidth = constants.maxWidth;
  const lineHeight = constants.lineHeight;

  if ((midAngle > 230 && midAngle < 330) || (midAngle > 0 && midAngle < 110) || (midAngle > 400 && midAngle < 550)) {
    x2 = cx + radius * Math.cos(-midAngle * RADIAN) * constants.multipliers.near;
    y2 = cy + radius * Math.sin(-midAngle * RADIAN) * constants.multipliers.near;
  }

  if ((midAngle > 250 && midAngle < 290) || (midAngle > 420 && midAngle < 550)) {
    x2 = cx + radius * Math.cos(-midAngle * RADIAN) * constants.multipliers.far;
    y2 = cy + radius * Math.sin(-midAngle * RADIAN) * constants.multipliers.far;
  }

  if ((midAngle > 260 && midAngle < 280) || (midAngle > 450 && midAngle < 550)) {
    x2 = cx + radius * Math.cos(-midAngle * RADIAN) * constants.multipliers.furthest;
    y2 = cy + radius * Math.sin(-midAngle * RADIAN) * constants.multipliers.furthest;
  }

  return (
    <MaxWidthText
      x={x2}
      y={y2}
      text={`${category} (${payload.value})`}
      maxWidth={maxWidth}
      lineHeight={lineHeight}
      fontSize={fontSize}
    />
  );
};

const PersonalityPieChart = ({ data, categories, baseHeight, chartHeight }: PersonalityPieChartProps) => {
  const categoriesData = categories.map((c) => ({ value: 1, label: c }));

  return (
    <div className="">
      <ResponsiveContainer width="99%" height={chartHeight}>
        <PieChart>
          <Pie
            dataKey="pie"
            startAngle={START_ANGLE}
            data={data}
            endAngle={START_ANGLE + 360}
            isAnimationActive={false}
            outerRadius={baseHeight + 12}
            paddingAngle={1}
            fill="#444"
            stroke="transparent"
            className="print:stroke-black print:fill-gray-200"
            // @ts-ignore
            label={<CustomLabel />}
            labelLine
          />
          <Pie
            dataKey="value"
            data={categoriesData}
            startAngle={START_ANGLE}
            endAngle={START_ANGLE + 360}
            isAnimationActive={false}
            innerRadius={baseHeight + 15}
            outerRadius={baseHeight + 35}
            paddingAngle={1}
            className="print:stroke-black"
          >
            {categoriesData.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                id={`category-${index}`}
                stroke="transparent"
                fill={COLORS[index % COLORS.length]}
                className="print:stroke-gray-800"
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomLegend = ({ categories }: { categories: string[] }) => {
  return (
    <div className="flex flex-wrap justify-center gap-x-5 gap-y-1">
      {categories.map((category, idx) => {
        return (
          <div key={idx} className="flex items-center justify-start gap-1">
            <div className="h-4 w-4 rounded-full" style={{ backgroundColor: COLORS[idx % COLORS.length] }} />
            <div>{category}</div>
          </div>
        );
      })}
    </div>
  );
};

export const PersonalityChart = ({
  professionalBehaviors,
}: {
  professionalBehaviors: PersonalitySurveyResults['professionalBehaviors'];
}) => {
  const { width } = useWindowSize();

  let BASE_HEIGHT = 130;
  let CHART_HEIGHT = 550;

  if (width < 768) {
    BASE_HEIGHT = 75;
    CHART_HEIGHT = 400;
  }

  if (width < 420) {
    BASE_HEIGHT = 64;
    CHART_HEIGHT = 350;
  }

  const grouped = _groupby(professionalBehaviors, (x) => x.localizedBehaviourName);

  const chartData = Object.values(grouped)
    .flat()
    .map((group) => ({
      category: group.localizedBehaviourAttributeName,
      value: group.numericalValue,
      pie: 1,
      id: group.id,
    }))
    .sort((a, b) => a.id - b.id);

  return (
    <div className="relative mb-8 w-full max-w-3xl">
      <PersonalityPieChart
        categories={Object.keys(grouped)}
        data={chartData}
        baseHeight={BASE_HEIGHT}
        chartHeight={CHART_HEIGHT}
      />
      <PersonalityRadarChart data={[...chartData].reverse()} baseHeight={BASE_HEIGHT} chartHeight={CHART_HEIGHT} />

      <CustomLegend categories={Object.keys(grouped)} />
    </div>
  );
};
