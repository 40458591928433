import * as React from 'react';
import cx from 'clsx';

import { Icon } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { type PersonalitySurveyResults } from 'services/api';

type ArrowProps = {
  orientation: 'left' | 'right' | 'up' | 'down';
};

const Arrow = ({ orientation }: ArrowProps) => {
  return (
    <Icon
      name="arrow-up"
      className={cx('text-primary h-4 w-4 text-lg', {
        '': orientation === 'up',
        'rotate-90 transform rtl:-rotate-90': orientation === 'right',
        '-rotate-90 transform rtl:rotate-90 ': orientation === 'left',
        'rotate-180 transform': orientation === 'down',
      })}
    />
  );
};

type HorizontalArrowSectionProps = {
  position: 'top' | 'bottom';
  text: string;
};

const HorizontalArrowSection = ({ position, text }: HorizontalArrowSectionProps) => {
  return (
    <div className="flex flex-col">
      {position === 'top' && <div className="-mb-2 flex justify-center tracking-widest md:text-lg">{text}</div>}

      <div className="flex items-center px-9 rtl:pe-12">
        <Arrow orientation="left" />
        <div className="-mx-2 h-px w-full bg-white" />
        <Arrow orientation="right" />
      </div>

      {position === 'bottom' && <div className="-mt-2 flex justify-center tracking-widest md:text-lg">{text}</div>}
    </div>
  );
};

type VerticalArrowSectionProps = {
  position: 'left' | 'right';
  text: string;
};

const VerticalArrowSection = ({ position, text }: VerticalArrowSectionProps) => {
  return (
    <div className="flex justify-center">
      {position === 'left' && (
        <div className="-me-2 flex justify-center tracking-tighter md:text-lg writing-vertical-lr orientation-upright rtl:orientation-sideways rtl:writing-horizontal-tb rtl:me-0 rtl:items-center">
          {text}
        </div>
      )}

      <div className="flex flex-col items-center">
        <Arrow orientation="up" />
        <div className="-my-2 h-full w-[1px] bg-white" />
        <Arrow orientation="down" />
      </div>

      {position === 'right' && (
        <div className="-ms-2 flex justify-center tracking-tighter md:text-lg writing-vertical-lr orientation-upright rtl:orientation-sideways rtl:writing-horizontal-tb rtl:-ms-0 rtl:items-center">
          <div className='rtl:ms-0'>{text}</div>
        </div>
      )}
    </div>
  );
};

type TileProps = {
  children: React.ReactNode;
  backgroundColor: string;
};

const Tile = ({ children, backgroundColor }: TileProps) => {
  return (
    <div
      className={cx(
        '2xs:break-normal 2xs:break-words flex aspect-square min-w-[50%] max-w-[50%] flex-1 flex-col items-center justify-center break-all p-4 text-center text-sm font-medium text-black md:text-base md:leading-snug print:border print:border-gray-300',
      )}
      style={{
        backgroundColor,
      }}
    >
      {children}
    </div>
  );
};

export const LearningStyleMatrix = ({
  learningStyle,
}: {
  learningStyle: PersonalitySurveyResults['learningStyle'];
}) => {
  const t = useTranslate();

  return (
    <div>
      <div className="max-w-lg">
        <HorizontalArrowSection
          position="top"
          text={t('orientation.personality_survey.results.learning_style.matrix.feel')}
        />

        <div className="flex">
          <VerticalArrowSection
            position="left"
            text={t('orientation.personality_survey.results.learning_style.matrix.do')}
          />

          <div className="flex flex-wrap">
            <Tile backgroundColor={learningStyle.upperLeftColor}>
              <div className="mb-0.5 font-bold underline">{learningStyle.upperLeftTitle}</div>
              {learningStyle.upperLeftDescription}
            </Tile>
            <Tile backgroundColor={learningStyle.upperRightColor}>
              <div className="mb-0.5 font-bold underline">{learningStyle.upperRightTitle}</div>
              {learningStyle.upperRightDescription}
            </Tile>
            <Tile backgroundColor={learningStyle.lowerLeftColor}>
              <div className="mb-0.5 font-bold underline">{learningStyle.lowerLeftTitle}</div>
              {learningStyle.lowerLeftDescription}
            </Tile>
            <Tile backgroundColor={learningStyle.lowerRightColor}>
              <div className="mb-0.5 font-bold underline">{learningStyle.lowerRightTitle}</div>
              {learningStyle.lowerRightDescription}
            </Tile>
          </div>

          <VerticalArrowSection
            position="right"
            text={t('orientation.personality_survey.results.learning_style.matrix.observe')}
          />
        </div>

        <HorizontalArrowSection
          position="bottom"
          text={t('orientation.personality_survey.results.learning_style.matrix.think')}
        />
      </div>
    </div>
  );
};
