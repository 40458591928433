import type { ReactNode } from 'react';

import { FormattedMessage } from '@interskillar/localization';
import { Icon, Button, Input, LeftIcon, RightIcon } from '@interskillar/ui';

const LargeMentorCard = () => {
  return (
    <div className="relative h-52 overflow-clip rounded-3xl">
      <img
        src="/mentors-placeholder-1.jpeg"
        className="h-full w-full object-cover object-[0px_-125px] md:object-center"
      />
      <div className="absolute start-0 top-0 flex h-full w-full flex-col justify-end bg-gradient-to-b from-gray-700/10 via-gray-900/10 to-black/40 px-3 py-2">
        <div className="text-contrast absolute end-4 top-2 flex items-center gap-1 rounded-full bg-white px-2 py-1">
          <Icon name="star" />
          4.9
        </div>
        <div className="text-xl font-medium text-white">Data scientist</div>
        <div className="text-white">Tadeas Izo</div>
      </div>
    </div>
  );
};

const SmallMentorCard = ({
  src,
  title,
  name,
  rating,
}: {
  src: string;
  title: string;
  name: string;
  rating: string;
}) => {
  return (
    <div className="relative h-44 overflow-clip rounded-3xl">
      <img src={src} className="h-full w-full object-cover object-[0px_0px] md:object-center print:block" />
      <div className="absolute start-0 top-0 flex h-full w-full flex-col justify-end bg-gradient-to-b from-gray-700/10 via-gray-900/10 to-black/40 px-3 py-2 print:bg-none print:text-black">
        <div className="text-contrast absolute end-4 top-2 flex items-center gap-1 rounded-full bg-white px-2 py-1 print:border print:border-gray-300">
          <Icon name="star" />
          {rating}
        </div>
        <div className="font-medium text-white print:text-black">{title}</div>
        <div className="text-sm text-white print:text-black">{name}</div>
      </div>
    </div>
  );
};

export const MentorsSection = () => {
  return (
    <div>
      <div className="mt-8 text-lg">
        <FormattedMessage
          id="home.mentors_coming_soon"
          values={{
            highlight: (...chunks: ReactNode[]) => <strong className="text-brand-pink">{chunks}</strong>,
          }}
        />
      </div>

      <div className="pointer-events-none opacity-50">
        <div className="mt-2 flex items-start justify-between gap-4">
          <Input
            className="mb-4 w-full rounded-full"
            id="search-for-mentors-input"
            placeholder="Search for mentors"
            tabIndex={-1}
          />
          <Button variant="secondary" className="rounded-full" tabIndex={-1}>
            <Icon name="filter" />
          </Button>
        </div>

        <LargeMentorCard />

        <div className="mt-6 flex justify-between">
          <div className="text-lg font-bold">Mentors in AI jobs</div>
          <div className="flex gap-2">
            <LeftIcon />
            <RightIcon />
          </div>
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4">
          <SmallMentorCard
            src="/mentors-placeholder-2.jpeg"
            title="Project manager"
            name="Leonarda de Karp"
            rating="5.0"
          />
          <SmallMentorCard
            src="/mentors-placeholder-3.jpeg"
            title="Software Engineer"
            name="Miranda Grande"
            rating="4.9"
          />
        </div>
      </div>
    </div>
  );
};
